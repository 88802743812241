<template>
  <div>
    <div class="w-full h-full px-5 py-5">
      <loader v-if="isLoadingFiles && isLoadingFolders" size="xxs" :loader-image="false" />
      <template v-else>
        <div class="flex flex-col gap-6">
          <div class="flex justify-start items-center gap-6">
            <div @click="$router.push({ name: 'EssEmployeeDocs' })" class="text-darkPurple cursor-pointer font-semibold text-base flex gap-2 items-center">
                <div><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> 
                Back
            </div>
            <div class="h-5 border border-borderGrey"></div>
            <h1 class="text-xl text-left font-extrabold">
              Documents
            </h1>
            <breadcrumb :items="breadcrumbs" />
          </div>
          <div class="flex flex-col gap-0">
            <folders
            :folders="allFolders"
            :current-folder-tab="currentFolderTab"
            :files="allFiles"
            :current-file-tab="currentFileTab"
            :current-tab="currentTab"
            :deleted="true"
            @updateActiveTab="updateActiveTab($event)"
            />
          </div>
        </div>
        <Card class="p-5">
          <template v-slot:footer>
            <card-footer
              @reload="reload()"
              @sortType="handleSort($event)"
              @option="handleOptionSelect($event)"
              @searchResult="handleSearch($event)"
              @actionModal="viewFilterTable($event)"
              search-placeholder="Search files/folders"
              :show-sort-filter="false"
            />
          </template>
        </Card>
        <div class="w-full h-full p-3 mb-8">
          <div class="uppercase text-xs text-flame font-extrabold p-2">All files ({{allFiles.length}})</div>
          <sTable
            :headers="filesHeader"
            :items="files"
            style="width: 100%"
            :loading="loading"
            aria-label="employee table"
            class="w-full"
            id="printMe"
            :pagination-list="allFilesMeta"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
            :has-number="false"
            :has-checkbox="true"
            v-if="allFiles.length > 0"
            >
            <template v-slot:item="{ item }">
              <template v-if="item.name">
                <div
                  class="flex flex-row items-center"
                >
                  <template>
                    <div>
                      <img
                        src="@/assets/images/files-multi.png"
                        style="width: 24px; height: 24px"
                      />
                    </div>
                  </template>
                  <div class="ml-2 text-sm flex flex-col justify-Start">
                    <span class="font-bold cursor-pointer text-jet">
                      {{ item.data.name }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-else-if="item.folder">
                <div
                  class="flex flex-row items-center"
                >
                  <template>
                    <div>
                      <icon icon-name="icon-folder" size="s" class="w-8 h-8 rounded text-romanSilver"/>
                    </div>
                  </template>
                  <div class="ml-2 text-sm flex flex-col justify-between">
                    <span class="font-bold cursor-pointer text-jet">
                      {{ item.data.folder.name }}
                    </span>
                    <span class="font-extrabold cursor-pointer text-romanSilver text-xs">
                      {{ item.data.folder?.fileCount ?? '-'  }} Files, {{ kbToSize(item.data.folder?.totalSize) ?? '-'  }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-else-if="item.group">
                <div v-if="item.data.group" class="ml-2 text-sm flex flex-col justify-between">
                  <span class="font-bold cursor-pointer text-jet">
                    {{ item.data.group.groupId.name ? item.data.group.groupId.name : '-' }}
                  </span>
                  <span class="font-extrabold cursor-pointer text-romanSilver text-xs">
                    {{ item.data.group.fileCount ? item.data.group.fileCount : '-'  }} Files, {{ item.data.group.totalSize ?  kbToSize(item.data.group.totalSize) : '-'  }}
                  </span>
                </div>
                <span v-else>-</span>
              </template>
              <template v-else-if="item.deletedBy">
                <div v-if="item.data.deletedBy"
                  class="flex flex-row items-center"
                >
                  <template>
                    <div>
                      <img v-if="item.data.createdBy.photo" :src="item.data.createdBy.photo" alt="profile pic" class="w-8 h-8 rounded" />
                      <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2">
                        {{ $getInitials(`${item.data.createdBy.fname} ${item.data.createdBy.lname}`) }}
                      </div>
                    </div>
                  </template>
                  <div class="flex flex-col ml-2">
                    <template class="text-darkPurple">
                        <div>
                          {{ item.data.createdBy ? item.data.createdBy.fname + ' ' + item.data.createdBy.lname : '-' }}
                        </div>
                    </template>
                    <div class="text-10 ">
                        <span class="font-normal text-blueYonder">
                          {{ $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy") }}
                        </span>
                    </div>
                  </div>
                </div>
                <div v-else class="font-extrabold cursor-pointer text-romanSilver uppercase text-xs">
                  {{ '-' }}
                </div>
              </template>
              <template v-else-if="item.remainingDays">
                <div class="flex flex-col">
                  <template class="text-darkPurple">
                      <div>
                        {{ item.data.remainingDays }}
                      </div>
                  </template>
                  <div class="text-10 ">
                      <span class="font-normal text-blueYonder">
                        {{ $DATEFORMAT(new Date(item.data.estimatedFinalDeletionDate), "MMMM dd, yyyy") }}
                      </span>
                  </div>
                </div>
              </template>
              <span v-else-if="item.fileId">
                <Menu left top="-140" margin="24" class="my-2 p-0">
                  <template v-slot:title>
                    <icon icon-name="more_icon" size="xs" />
                  </template>
                  <div class="w-48 flex flex-col justify-start items-center gap-3 px-3 py-2">
                    <div class="w-full" @click="actions('restore', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="restore-icon" class-name="text-chartPurple" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Restore</p>
                      </div>
                    </div>
                    <div class="w-full" @click="actions('delete', item.data)">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="delete" class-name="text-desire" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Delete File</p>
                      </div>
                    </div>
                  </div>
                </Menu>
              </span>
              </template>
          </sTable>
          <div class="flex flex-col justify-center items-center w-full p-8 m-8 gap-5" v-if="allFiles.length === 0 && !isLoadingFiles">
            <img :src="require('@/assets/images/emptyFolders.svg')" alt="not_found">
            <div class="">
              There are no files deleted yet. Deleted files will display here when a file is deleted.
            </div>
          </div>
        </div>
      </template>
    </div>
    <FilterTable
      v-if="filterTable"
      :filter-data="filterData"
      @close="closeFilter($event)"
      @getParams="searchFilter($event)"
    />
    <Delete
      v-if="openDelete"
      @close="close($event)"
      @refresh="refresh($event)"
      :isRecycleBin="true"
      :current-tab="`Files(${  this.allFiles.length })`"
      :id="selectedItem.fileId"
    />
    <Restore
      v-if="openRestore"
      @close="close($event)"
      @refresh="refresh($event)"
      :current-tab="`Files(${  this.allFiles.length })`"
      :id="selectedItem.fileId"
    />
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Button from "@/components/Button";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import { mapState } from "vuex";
import Menu from "@/components/Menu";
import CardFooter from "@/components/CardFooter";
import Card from "@/components/Card";
import FilterTable from "@/components/FilterTable";
import Folders from './folders';
import Delete from '@/components/documents/Delete';
import Restore from "@/components/documents/Restore";

export default {
  name: "RecycleBin",
  components: {
    Breadcrumb,
    Tab,
    Folders,
    Button,
    STable,
    Menu,
    Card,
    CardFooter,
    FilterTable,
    Delete,
    Restore
  },
  data() {
    return {
      functions: [],
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      isActive: null,
      dialogDel: false,
      filterTable: false,
      sortType: null,
      functionIds: [],
      currentTab: "files",
      isLoading: false,
      isLoadingFolders: false,
      isLoadingFiles: false,
      filterArray: [
        { header: "Folder", value: "folderId", optionsBox: [] },
        { header: "Last Modified Date", value: "modifiedDate", optionsBox: [
              {
                name : "Today",
                id : "today"
              },
              {
                name : "Last 7 days",
                id : "7days"
              },
              {
                name : "30 days",
                id : "30days"
              },
              {
                name : "This Year",
                id : "thisyear"
              },
              {
                name : "Last Year",
                id : "lastyear"
              },
              {
                name : "Custom",
                id : "custom"
              },
            ]
        },
        { header: "People", value: "people", optionsBox: [] },
        { header: "Group", value: "groupId", optionsBox: [] },
      ],
      designationIds: [],
      userDetails: {},
      showPrivilegeError: false,
      allFiles: [],
      allFolders: [],
      allFilesMeta: {},
      allFoldersMeta: {},
      allGroups: [],
      currentFolderTab: 'all',
      currentFileTab: 'all',
      selectedItem: '',
      openRestore: false,
      openDelete: false
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.employee.loading,
      employees: (state) => state.employee.employees,
      metaData: (state) => state.employee.metaData,
    }),
    filterData() {
      if(this.currentTab.toLowerCase().includes('files')){
        return this.filterArray.filter(i => i.value === 'folderId' || i.value === 'groupId')
      }
        return this.filterArray.filter(i => i.value === 'groupId')
    },
    filesHeader() {
      return [
        { title: "File name", value: "name", width: 20 },
        { title: "Folder name", value: "folder" },
        { title: "Group", value: "group" },
        { title: "Deleted By", value: "deletedBy" },
        { title: "Final Deletion", value: "remainingDays" },
        { title: "", value: "fileId", image: true }
      ];
    },
    files() {
      if(this.currentFileTab === 'all'){
        return this.allFiles
      }
        const filesForCurrentTab = this.allFiles.filter((file)=>file.fileId === this.currentFileTab)
        return filesForCurrentTab
    },
    breadcrumbs() {
      return [
        {
          disabled: false,
          text: "Documents",
          href: "OrganisationCompanyDocuments",
          id: "DocumentMgt",
        },
        {
          disabled: false,
          text: "Employee Documents",
          href: "EmployeeDocuments",
          id: "EmpDocuments",
        },
        {
          disabled: false,
          text: "Recycle Bin",
          href: "RecycleBin",
          id: "RecycleBin",
        },
      ];
    },
  },
  methods: {
    actions(type, item) {
      this.selectedItem = item;
      switch (type) {
        case 'delete':
          this.openDelete = true;
          break;
        case 'restore':
          this.openRestore = true;
          break;

        default:
          break;
      }
    },
    close(type) {
      switch (type) {
        case 'delete':
          this.openDelete = false;
          break;
        case 'restore':
          this.openRestore = false;
          break;

        default:
          break;
      }
    },
    updateActiveTab({id, type}) {
      if(type === 'folder'){
        this.currentFolderTab = id;
        this.allFoldersMeta = {...this.allFoldersMeta,to : this.folders.length, total:this.folders.length }
      }else{
        this.currentFileTab = id;
        this.allFilesMeta = {...this.allFilesMeta,to : this.files.length, total:this.files.length }
      }
    },
    async refresh() {
      await this.getAllDeletedEmployeesFolders();
      await this.getAllDeletedEmployeesFiles();
      await this.getAllGroups();
    },
    viewFilterTable(value) {
      if (value) {
        this.filterTable = true;
      }
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.sortType = sortType;
      this.queryUpdate();
    },
    searchFilter(params) {
      this.getAllDeletedEmployeesFiles(params);
      this.filterTable = false;
    },
    closeFilter(val) {
      if (val) {
        this.filterTable = false;
      }
    },
    async getAllDeletedEmployeesFiles(params='') {
      this.isLoadingFiles = true;
      try{
        const { data } = await this.$_getAllDeletedEmployeesFiles(this.$orgId);
        this.isLoadingFiles = false;
        this.allFiles = data.files === 0 ? [] : data.files;
        this.allFilesMeta = data.meta ? data.meta : {};
      }catch(err){
        console.log(err)
      }
    },
    async getAllDeletedEmployeesFolders(params='') {
      this.isLoadingFolders = true;
      const { data } = await this.$_getAllDeletedEmployeesFolders(this.$orgId);
  
      this.isLoadingFolders = false;
      this.allFolders = data.folders === 0 ? [] : data.folders;
      this.allFoldersMeta = data.meta;

      const people = data.folders.map((folder)=>({
          id:folder.createdBy.id,
          name:`${folder.createdBy.fname} ${folder.createdBy.lname}`,
        }));
      const uniquePeople = [...new Set(people.map(item => item.name))].map(name => {
          const matchingItem = people.find(item => item.name === name);
          return { id: matchingItem.id, name: matchingItem.name };
      });
      this.filterArray[0] = { header: "Folder", value: "folderId", optionsBox: data.folders.map(item=>( {id:item.folderId, name:item.name})) };
      this.filterArray[2] = { header: "People", value: "people", optionsBox: uniquePeople };
    },
    async getAllGroups() {
      const { data } = await this.$_getAllGroups(
        this.$orgId
      );
      this.allGroups = data.groups === 0 ? [] : data.groups;
      this.filterArray[3] = { header: "Groups", value: "groupId", optionsBox: data.groups.map(item=>( {id:item.id, name:item.name})) };
    },
    getUserPhotos(data) {
      const userPhotos = [];
      data.forEach(item => {
          item.permissionUsers.forEach(permissionUser => {
            userPhotos.push({
              name:`${permissionUser.accessUser?.fname  } ${  permissionUser.accessUser?.lname}`,
              photo:permissionUser.accessUser.photo ? permissionUser.accessUser.photo : null,
            });
          });
      });
      return userPhotos;
    },
    kbToSize(kb) {   
      const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
      if (kb === 0) return '0 KB';
      if (kb < 1) return `${(kb * 1024).toFixed(1)} KB`; // Converted small KB to Bytes
          const i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10);
          const sizeIndex = Math.min(i, sizes.length - 1);
      if (sizeIndex === 0) return `${kb.toFixed(1)} ${sizes[sizeIndex]}`;
      return `${(kb / (1024 ** sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`;
    }
  },
  async mounted() {
    await this.getAllDeletedEmployeesFiles();
    await this.getAllDeletedEmployeesFolders();
    await this.getAllGroups();
  },
};
</script>

<style scoped>
  .list-image{
    height: 500px !important;
  }

.text-10{
  font-size: 10px !important;
}
</style>
